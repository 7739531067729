<template>
  <div class="photos">
    <img src="../assets/photos/image.png" />
    <img src="../assets/photos/IMG_1400.jpg" />
    <img src="../assets/photos/IMG_1443_edited.jpg" />
    <img src="../assets/photos/IMG_1461.jpg" />
    <img src="../assets/photos/IMG_1463.jpg" />
    <img src="../assets/photos/IMG_1648.jpg" />
    <img src="../assets/photos/IMG_1747.jpg" />
    <img src="../assets/photos/IMG_1908.jpg" />
    <img src="../assets/photos/IMG_1933.jpg" />
    <img src="../assets/photos/IMG_1971.jpg" />
    <img src="../assets/photos/IMG_1990.jpg" />
    <img src="../assets/photos/IMG_7282.jpg" />
    <img src="../assets/photos/IMG_7841.jpg" />
    <img src="../assets/photos/IMG_Phone_1.jpg" />
    <img src="../assets/photos/IMG_Phone_2.jpg" />
    <img src="../assets/photos/IMG_Phone_3.jpg" />
    <img src="../assets/photos/IMG_Phone_4.jpg" />
    <img src="../assets/photos/IMG_Phone_5.jpg" />
  </div>
</template>
<style scoped>
img {
  width: 100%;
  height: auto;
}
</style>
