<template>
  <div class="message">
    <h1>Happy Birthday Mom!</h1>
    <h2>
      Unfortunately, there is no pop-up card this year, but hopefully you like
      this website. College has been enjoyable so far but I have missed you.
      It's just easy to talk to you and I feel like you understand what is going
      in my life. Something else I've found is that planning is not easy and
      I've already forgotten a few small things like COVID tests which I had to
      reschedule for the next day. I do have a great foundation though and I
      think I've adapted well to the differnet situations like bathrooms, food
      access times, getting my own mail, making it to classes, tracking
      homework, and laundry among many others. Overall, there have definitely
      been a lot of things I've needed to get used to but I'm really enjoying
      myself so far. I hope things are going great at home and I look forward to
      seeing you in person at parents weekend, hopefully.
    </h2>
    <h1>Love, Aidan ❤</h1>
  </div>
</template>
<style scoped>
h1 {
  color: rgb(100, 187, 100);
}
h2 {
  color: rgb(89, 160, 89);
}
</style>
